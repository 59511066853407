// eslint-disable-next-line import/no-unresolved
import 'expose-loader?exposes=NDTjs|override!exports-loader?exports=NDTjs!../../../js/responsive/ndt/ndt-browser-client.js';

import { ndt7core } from 'exports-loader?exports=ndt7core!../../../js/responsive/ndt/ndt7/ndt7-core';
import { NDTWrapper } from 'exports-loader?exports=NDTWrapper!../../../js/responsive/ndt/ndt-wrapper.js';

(function () {
    'use strict';
    WhistleOut.SpeedTest = {};
    WhistleOut.SpeedTest.canUseNdt7 = function () {
        'use strict';
        try {
            // TODO: Consider not using eval(), https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
            /* eslint-disable no-eval */
            eval('var a = new URL(\'https://www.whistleout.com.au\')');
            eval('var bar = () => {return true}');
            eval('const sock = \'a\'');
        } catch (e) { return false; }

        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        return isSafari ? false : true;
    };
    WhistleOut.SpeedTest.getSpeedTestParameters = function (successCallback, failedCallback) {
        // we are commenting this out as ndt7 token doesn't last too long, we have to figure out this but it is not in the documentation.
        //var cookieValue = Cookies.get('woSpeedTest');
        var useNdt7 = WhistleOut.getSiteConfiguration().useNdt7Protocol;
        var canUseNdt7 = WhistleOut.SpeedTest.canUseNdt7();

        //if (cookieValue) {
        //    var cookieParsed = JSON.parse(cookieValue);
        //    if (useNdt7 && canUseNdt7 && cookieParsed.protocol === 'ndt7' || useNdt7 === false && !cookieParsed.protocol) {
        //        successCallback(cookieParsed);
        //        return;
        //    }
        //}

        var mlabNsService = '';
        var mlabNsUrl = '';

        if (useNdt7 && canUseNdt7) {
            mlabNsService = 'v2/nearest/ndt/ndt7';
            mlabNsUrl = 'https://locate.measurementlab.net/';
        } else {
            // TODO: Can we use === instead of == here?
            // eslint-disable-next-line eqeqeq
            mlabNsService = 'https:' == location.protocol ? 'ndt_ssl' : 'ndt';
            mlabNsUrl = 'https://mlab-ns.appspot.com/';
        }

        wo$.ajax({
            url: mlabNsUrl + mlabNsService + '?format=json',
            dataType: 'json',
            success: function (resp) {
                if (useNdt7 && canUseNdt7) {
                    resp.protocol = 'ndt7';
                }

                //Cookies.set('woSpeedTest', JSON.stringify(resp), { path: '/', expires: 1 });
                successCallback(resp);
            },
            error: function (jqXHR, errStatus, errText) {
                failedCallback(jqXHR, errStatus, errText);
            }
        });
    };

    WhistleOut.SpeedTest.testSpeed = function (server, testDownloadSpeed, testUploadSpeed, callback) {
        var client = null;

        function getNumber(value) {
            if (value && !isNaN(value))
                return value;
            return null;
        }

        function getJustfiedSpeed(speedInKB) {
            var e = Math.floor(Math.log(speedInKB) / Math.log(1000));
            var fixedNum = 2;
            if (speedInKB > 100) {
                fixedNum = 0;
            } else if (speedInKB > 10) {
                fixedNum = 1;
            }
            return (speedInKB / Math.pow(1000, e)).toFixed(fixedNum);
        }

        function updateUploadSpeeds(result) {
            result.uploadSpeedDone = false;
            if (result.status === 'Outbound' || result.status === 'Inbound') {
                var value = getNumber(client.getNDTvar('ClientToServerSpeed'));
                if (value != null) {
                    result.uploadSpeed = getJustfiedSpeed(value);
                    if (result.status === 'Inbound') {
                        result.uploadSpeedDone = true;
                    }
                } else {
                    result.uploadSpeed = null;
                }
            }
        }

        function updateDownloadSpeeds(result) {
            if (result.status === 'Inbound') {
                var value = getNumber(client.getNDTvar('ServerToClientSpeed'));
                if (value != null) {
                    result.downloadSpeed = getJustfiedSpeed(value);
                } else {
                    result.downloadSpeed = null;
                }
            }
        }

        function refreshSpeed(callback) {
            var message = client.get_errmsg();
            var status = client.get_status();
            var isCompleted = message === 'Test completed';
            var hasErrors = message !== 'Test completed' && message.length > 0;
            var latency = getNumber(client.getNDTvar('MinRTT'));
            console.log(message);
            var result = {
                'status': status,
                'isCompleted': isCompleted,
                'hasErrors': hasErrors
            };
            updateUploadSpeeds(result);
            updateDownloadSpeeds(result);
            if (!isCompleted && !hasErrors) {
                callback(result);
                setTimeout(function () { refreshSpeed(callback); }, 1000);
            } else {
                if (latency) {
                    result.latency = getNumber(client.getNDTvar('MinRTT'));
                }
                result.lastResultDate = new Date();
                Cookies.set('woSpeedTestResult', JSON.stringify(result), { path: '/', expires: 30 });
                callback(result);

                var event = {
                    type: 'woSpeedTest.resultUpdated',
                    detail: {
                        sender: this,
                        result: result
                    }
                };
                wo$.event.trigger(event);
            }
        }

        var testsToExecute = 32;
        if (testDownloadSpeed) {
            testsToExecute = 4 | testsToExecute;
        }
        if (testUploadSpeed) {
            testsToExecute = 2 | testsToExecute;
        }

        client = new NDTWrapper(server, testsToExecute);
        client.run_test(server);
        refreshSpeed(callback);
    };

    WhistleOut.SpeedTest.runNdt7Test = function (server, testName, instance, callback, latencyInstance) {

        var updateView = function (instance, appInfo) {
            var elapsed = appInfo.ElapsedTime / 1e06; /* second */
            var speed = appInfo.NumBytes / elapsed; /* B/s    */
            speed *= 8; /* bit/s  */
            speed /= 1e06; /* Mbit/s */

            instance.text(speed.toFixed(1));
        };

        var updateLatency = function (instance, tcpInfo) {
            var speed = tcpInfo.MinRTT;
            speed /= 1000; /* Mbit/s */

            instance.text(Math.round(speed));
        };

        var useNdt7 = WhistleOut.getSiteConfiguration().useNdt7Protocol;
        if (!useNdt7) {
            return;
        }

        ndt7core.run(server,
            testName,
            function (ev, val) {
                if (ev === 'complete') {
                    if (testName === 'download') {
                        var result = {
                            'status': 'complete',
                            'isCompleted': true,
                            'hasErrors': false
                        };
                        result.lastResultDate = new Date();
                        result.downloadSpeed = instance.first().text();
                        Cookies.set('woSpeedTestResult', JSON.stringify(result), { path: '/', expires: 30 });
                    }

                    if (callback !== undefined) {
                        callback();
                    }
                    return;
                }
                if (ev === 'measurement' &&
                    val.AppInfo !== undefined &&
                    val.Origin === 'client') {
                    updateView(instance, val.AppInfo);
                }

                if (ev === 'measurement' &&
                    val.TCPInfo !== undefined &&
                    val.Origin === 'server' &&
                    latencyInstance) {
                    updateLatency(latencyInstance, val.TCPInfo);
                }
            });
    };

    WhistleOut.SpeedTest.init = function (parent) {
        if (!parent)
            parent = wo$('body');
        var container = parent.find('div.speed-test-widget');
        var speedTiers = container.data('speed-tiers');
        var displayError = function (instance) {
            instance.find('div[data-stage-progress]').addClass('hidden');
            instance.find('div[data-stage-error]').removeClass('hidden');
            Cookies.set('woSpeedTest', null, { path: '/', expires: -1 });
        };

        var testUpload = function (server, instance) {
            try {
                WhistleOut.SpeedTest.testSpeed(server, false, true, function (result) {
                    if (result.uploadSpeed)
                        instance.find('span[data-current-upload]').text(result.uploadSpeed);
                    if (result.hasErrors && !result.uploadSpeed) {
                        Cookies.set('woSpeedTest', null, { path: '/', expires: -1 });
                        instance.find('span[data-current-upload]').text('-');
                    }
                    if (result.isCompleted || result.hasErrors) {
                        instance.find('button[data-download-start]').removeAttr('disabled');
                        instance.find('span[data-current-upload]').parent().removeClass('c-gray-light');
                        instance.find('span[data-current-upload]').prev().addClass('hidden');
                    }
                    console.log(result);
                });
            } catch (e) {
                displayError(instance);
            }
        };

        var testDownload = function (server, instance) {
            try {
                WhistleOut.SpeedTest.testSpeed(server,
                    true,
                    false,
                    function (result) {
                        if (result.downloadSpeed)
                            instance.find('span[data-current-download]').text(result.downloadSpeed);
                        if (result.hasErrors && !result.downloadSpeed) {
                            displayError(instance);
                        } else if (result.isCompleted || result.hasErrors) {
                            var tier = speedTiers.findIndex(function (e) { return e > result.downloadSpeed * 1000; }) + 1;
                            instance.find('span[data-current-tier]').removeClass();
                            instance.find('span[data-current-tier]').addClass('fa font-10 fa-speed-' + tier);
                            instance.find('div[data-stage-progress]').addClass('hidden');
                            instance.find('div[data-stage-results]').removeClass('hidden');
                            instance.find('span[data-latency]').text(result.latency);
                        }
                        console.log(result);
                    });
            } catch (e) {
                displayError(instance);
            }
        };

        var runNdt7Test = function (server, testName, instance) {
            try {
                WhistleOut.SpeedTest.runNdt7Test(server,
                    testName,
                    instance.find('span[data-current-' + testName + ']'),
                    function () {
                        if (testName === 'download') {
                            instance.find('span[data-current-tier]').removeClass();
                            instance.find('div[data-stage-progress]').addClass('hidden');
                            instance.find('div[data-stage-results]').removeClass('hidden');

                        } else {
                            instance.find('button[data-download-start]').removeAttr('disabled');
                            instance.find('span[data-current-upload]').parent().removeClass('c-gray-light');
                            instance.find('span[data-current-upload]').prev().addClass('hidden');
                        }
                    },
                    instance.find('span[data-latency]'));
            } catch (e) {
                displayError(instance);
            }
        };

        container.find('button[data-show-more-info]').off('click').on('click', function () {
            var instance = wo$(this).closest('div.speed-test-widget');
            wo$(this).addClass('hidden');
            wo$(this).next().find('div[data-more-info]').collapse('show');
            instance.find('span[data-current-upload]').text('0');
            instance.find('span[data-current-upload]').parent().addClass('c-gray-light');
            instance.find('span[data-current-upload]').prev().removeClass('hidden');
            instance.find('button[data-download-start]').attr('disabled', 'disabled');
            WhistleOut.SpeedTest.getSpeedTestParameters(function (resp) {
                if (resp.protocol === 'ndt7') {
                    const result = resp.results[0];
                    runNdt7Test(result.urls['wss:///ndt/v7/upload'], 'upload', instance);

                } else {
                    testUpload(resp.fqdn, instance);
                }

            }, function () {
                displayError(instance);
            });
        });

        container.find('button[data-download-start]').off('click').on('click', function () {
            var instance = wo$(this).closest('div.speed-test-widget');
            instance.find('button[data-show-more-info]').removeClass('hidden');
            instance.find('div[data-more-info]').collapse('hide');
            instance.find('div[data-stage-error]').addClass('hidden');
            instance.find('div[data-stage-begin]').addClass('hidden');
            instance.find('div[data-stage-results]').addClass('hidden');
            instance.find('div[data-stage-progress]').removeClass('hidden');
            instance.find('span[data-current-download]').text('0');
            WhistleOut.SpeedTest.getSpeedTestParameters(function (resp) {
                if (resp.protocol === 'ndt7') {
                    const result = resp.results[0];
                    instance.find('span[data-server-location]').text(result.location.city + ', ' + result.location.country);
                    runNdt7Test(result.urls['wss:///ndt/v7/download'], 'download', instance);

                } else {
                    instance.find('span[data-server-location]').text(resp.city + ', ' + resp.country);
                    testDownload(resp.fqdn, instance);
                }
            }, function () {
                displayError(instance);
            });
        });
        var speedTestResult = Cookies.get('woSpeedTestResult');
        if (speedTestResult && speedTestResult.length) {
            speedTestResult = JSON.parse(speedTestResult);
            var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            container.find('[data-speed-test-date]')
                .text(new Date(speedTestResult.lastResultDate).toLocaleDateString('en-AU', dateOptions));
            container.find('[data-speed-test-result]').text(speedTestResult.downloadSpeed);
            container.find('[data-last-test]').removeClass('hidden');
        } else {
            container.find('[data-last-test]').addClass('hidden');
        }
    };
}());
wo$(function () {
    WhistleOut.SpeedTest.init(wo$('body'));
});